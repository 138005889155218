<template>
  <div>
    <p class="p4">
      <i class="fas fa-info-circle"></i>
      Importante: Só é possível fazer o direcionamento para Pessoas Jurídicas
      das quais o Cooperado é sócio e que sejam cooperados da Unimed Grande
      Florianópolis.
    </p>

    <b-card class="custom-card my-4" v-if="!$props.newRegister">
      <b-form-select
        v-model="selectedOption"
        :options="selectOptions"
        v-on:change="onChangeFormSelect"
        size="lg"
        class=" custom-select-head"
      />
    </b-card>

    <b-card class="custom-card  my-4" v-if="showForm()" border-variant="light">
      <b-form-row>
        <b-col>
          Nome fantasia
        </b-col>
        <b-col>
          <b-form-input
            v-model.lazy.trim="form.name"
            :state="validateField('name')"
            placeholder="Digite"
          />
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col>
          CNPJ
        </b-col>
        <b-col>
          <CustomMaskInput
            :initialValue="form.cnpj"
            :options="cnpjMaskOptions"
            :state="validateCnpj('cnpj')"
            placeholder="Digite"
          ></CustomMaskInput>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col>
          <UploadFile
            @updateFile="eventToUpdateFile"
            :multiple="true"
            description="Anexar documento para comprovação"
            msgErro="É necessário inserir um anexo."
            :state="validateFieldFile('files')"
            :required="true"
            v-model.lazy.trim="form.files"
          />
        </b-col>
      </b-form-row>
    </b-card>
  </div>
</template>

<script>
import UploadFile from "../../../../../components/upload-file/UploadFile.vue";
import CustomMaskInput from "../../../../../components/forms/custom-mask-input/CustomMaskInput.vue";
import { inject } from "@vue/composition-api";
import { cnpj } from "cpf-cnpj-validator";

const DefaultForm = {
  description: null,
  cnpj: null,
  files: [],
  originalData: null,
};
const InvalidEventHandler = () => {
  console.warn("Invalid onChange handler");
};

const CnpjMaskOptions = {
  numericOnly: true,
  delimiters: [".", ".", "/", "-"],
  blocks: [2, 3, 3, 4, 2],
  onValueChanged: InvalidEventHandler,
};

export default {
  props: ["loadedFormDataOptions", "newRegister"],
  components: { CustomMaskInput, UploadFile },
  setup() {
    const formData = inject("formData");
    const setForm = inject("setForm");

    return { formData, setForm };
  },
  mounted() {
    this.initForm();
  },
  data() {
    return {
      formSubmited: false,
      form: {
        ...DefaultForm,
      },
      validations: {},
      cnpjMaskOptions: {
        ...CnpjMaskOptions,
        onValueChanged: this.onChangeCpnj,
      },
      selectedOption: null,
      selectOptions: [],
    };
  },
  methods: {
    initForm() {
      if (!this.$props.newRegister) {
        const loadedFormDataOptions = this.$props.loadedFormDataOptions;
        this.selectOptions = this.buildSelectOptions(loadedFormDataOptions);
      } else {
        this.formData = {
          form: { ...this.form },
          onSubmitHandler: this.onSubmit,
          formSelected: false,
        };
      }
    },
    showForm() {
      return this.selectedOption || this.$props.newRegister;
    },
    buildSelectOptions(formDataOptions) {
      return [
        {
          value: null,
          text: "Selecione um direcionamento de pagamento",
          disabled: true,
        },
        ...formDataOptions.map((formData) => ({
          value: formData,
          text: formData.description,
        })),
      ];
    },
    onChangeFormSelect(formData) {
      if (!formData) {
        this.formData = {
          form: { ...DefaultForm },
          onSubmitHandler: this.onSubmit,
          onDeleteHandler: this.onDelete,
          formSelected: false,
        };
        this.form = { ...DefaultForm };
        this.setForm(this.form);
        return;
      }

      const form = { ...this.form, ...formData };
      this.form = form;
      this.form.originalData = { ...this.form };
      this.formData = {
        form: { ...this.form },
        onSubmitHandler: this.onSubmit,
        onDeleteHandler: this.onDelete,
        formSelected: true,
      };
      this.setForm(this.form);
    },
    onChangeCpnj(e) {
      this.form.cnpj = e.target.rawValue;
    },
    validateCnpj(name) {
      let value = this.form[name];
      let validation = cnpj.isValid(value);
      this.validations[name] = validation;
      if (!this.formSubmited) return null;
      return validation;
    },
    validateFieldFile(name) {
      let value = this.form[name];
      const validation = !!value && value.length > 0;
      this.validations[name] = validation;
      if (!this.formSubmited) return null;
      return validation;
    },
    validateField(name) {
      let value = this.form[name];

      let validation = !!value;
      this.validations[name] = validation;
      if (!this.formSubmited) return null;
      return !!value;
    },
    requiredFieldsFilled() {
      return Object.values(this.validations).every(
        (fieldValue) => fieldValue == true
      );
    },
    eventToUpdateFile(files) {
      this.form.files = files;
    },
    onSubmit() {
      this.setForm(this.form);
      this.formSubmited = true;
      if (this.requiredFieldsFilled()) {
        return true;
      }
    },
    onDelete() {
      return this.selectedOption;
    },
  },
};
</script>

<style lang="scss" scoped>
div.form-row {
  padding: 0.5rem;
  border-bottom: 1px solid $gray-300;
  &:last-child {
    border-bottom: none;
  }
}

input.form-control {
  text-align: right;
  border: 1px solid transparent;
}

select.custom-select {
  text-align: right;
  border: 1px solid transparent;
}
</style>
