<template>
  <div>
    <AlterFormLayout
      modalId="payment-alter-modal"
      formTitle="Alterar direcionamento de pagamento"
      attentionModalId="payment-attention-modal"
      deleteButtonText="Solicitar exclusão"
      cancelButtonText="Cancelar"
      submitButtonText="Enviar solicitação de alteração"
      :onSubmitEvent="onSubmit"
      :onDeleteEvent="onDeleteForm"
      ref="alter-modal"
       :disableEvent="this.$store.getters.isAdmin"
    >
      <template #content>
        <PaymentRoutingForm
          :loadedFormDataOptions="$props.loadedFormDataOptions"
        />
      </template>
    </AlterFormLayout>
    <success-modal
      ref="success-modal"
      description="Sua solicitação de alteração do direcionamento de pagamento foi enviada."
      buttonText="Ok, entendi!"
      subdescription="Os dados alterados serão analisados e atualizados após a validação."
      :protocol="this.protocol"
    />

    <attention-modal
      modalId="payment-attention-modal"
      title="Atenção!"
      submitButtonText="Sim, solicitar a exclusão"
      cancelButtonText="Não, cancelar a solicitação"
      :onAction="onDeleteAttentionModal"
      ref="attetion-modal"
    >
      <template slot="description">
        <div class="justify-content-center mt-5">
          <div>
            <p class="p1 mb-5">
              Todas as informações deste direcionamento de pagamento serão
              perdidas com esta ação.
            </p>
          </div>
          <div>
            <p class="p1 mb-5">
              Deseja mesmo solicitar a exclusão deste direcionamento de
              pagamento?
            </p>
          </div>
        </div>
      </template>
    </attention-modal>
  </div>
</template>

<script>
import PaymentRoutingForm from "./PaymentRoutingForm.vue";
import AlterFormLayout from "../../../../../layouts/AlterFormLayout.vue";
import {
  updatePaymentRouting,
  deletePaymentRouting,
} from "../../../../../services/payment-options/paymentOptionsService";
import SuccessModal from "../../../../../components/base-modals/SuccessModal.vue";
import AttentionModal from "../../../../../components/base-modals/AttentionModal.vue";

export default {
  props: ["loadedFormDataOptions"],
  components: {
    AlterFormLayout,
    PaymentRoutingForm,
    SuccessModal,
    AttentionModal,
  },
  data() {
    return {
      selectedOption: null,
      protocol: "",
    };
  },
  methods: {
    showSuccessModal() {
      const modal = this.$refs["success-modal"].$children[0];
      modal.show();
    },
    async onSubmit(formData) {
      this.protocol = await updatePaymentRouting(formData, formData.files);
      const modal = this.$refs["success-modal"].$children[0];
      modal.show();
    },
    async onDeleteForm(formData) {
      this.protocol = await deletePaymentRouting(formData);
      this.showSuccessModal();
    },
    async onDeleteAttentionModal(action) {
      if (action) {
        const alterModal = this.$refs["alter-modal"];
        alterModal.doDelete();
      }
    },
  },
};
</script>
