<template>
  <b-table
    class="custom-table spaced-text"
    :items="$props.paymentDestination"
    :fields="tableFields"
  >
    <template v-slot:head(description)="data">{{ data }} </template>
    <template v-slot:cell(description)="row">
      <div class="font-weight-bold">
        {{ row.item.description }} - CNPJ: {{ row.item.cnpj }}
      </div>
    </template>
    <template v-slot:cell(status)="row">
      <img
        src="@/assets/icons/checkbox-marked-circle.svg"
        data-toggle="tooltip"
        data-placement="top"
        v-show="row.item.status == statusActiveLabel"
        :id="'button-' + row.index + row.item.cnpj"
      />
      <b-tooltip
        :target="'button-' + row.index + row.item.cnpj"
        triggers="hover"
      >
        Status: {{ row.item.status }} <br />
        Data Início: {{ new Date(row.item.date).toLocaleDateString("pt-BR") }}
      </b-tooltip>
    </template>
  </b-table>
</template>

<script>
const statusActiveLabel = "ATIVO";
const tableFields = [
  {
    key: "description",
    label: "description",
    tdClass: "text-left",
    thStyle: {
      display: "none",
    },
  },
  {
    key: "status",
    label: "status",
    tdClass: "text-right customColumnImg",
    thStyle: {
      display: "none",
    },
  },
];

export default {
  props: ["paymentDestination"],
  data() {
    return {
      tableFields: tableFields,
      statusActiveLabel: statusActiveLabel,
    };
  },
};
</script>

<style lang="scss">
.tooltip-inner {
  background-color: #6a6a6a;
}
tbody {
  color: #804e4e;
}

.customColumnImg {
  padding-right: 1rem !important;
}
</style>
