<script>

import resolve_img_url from "../../../../utils/image/image-utils";

const AccordionList = {
  props: [
    "principalPayment",
  ],
  data() {
    return {
      payment: null,
    };
  },
  components: {  },
  render() {
    const payment = this.$props.principalPayment || null;

    return (
      <div class="principal-payment">
        <div>
          <h6 class="title-custom">
            {`${payment.description} - ${payment.cnpj}`}
          </h6>
          <p class="p4">PJ principal para direcionamento de pagamentos</p>
        </div>
    
        <img
          src={resolve_img_url("path-check-decagram.svg")}
        />
      </div>
    );
  },
};

export default AccordionList;
</script>

<style lang="scss" scoped>
.principal-payment {
  display: flex;
  align-items: center;
  padding: 1.2rem 5.25rem 1rem 4rem;
  border-radius: 1rem;

  background-color: white;
  width: 100%;
  text-align: left;
  height: 5rem;

  img {
    margin-left: auto;
  }
}

.title-custom {
  color: var(--primary);
  margin-bottom: 0px !important;
}
</style>