<template>
  <div>
    <b-row class="justify-content-center mb-4">
      <p class="p4">
        Confira seus dados e, caso haja divergências, solicite alteração
        cadastral a qualquer momento.
      </p>
    </b-row>
    <div v-show="!this.loading">
      <PaymentPrincipal
        v-show="!!principalPayment && !!principalPayment.cnpj && !!principalPayment.description"
        :key="this.principalPayment.cnpj"
        :principalPayment="this.principalPayment"
        class="custom-payment"
      />
      <AccordionList
        titleKeyName="description"
        :items="this.tableItems"
        :itemsFields="this.tableFields"
        :getCustomTable="this.getCustomTable"
      >
      </AccordionList>
      <PaymentRoutingAddModal />
      <PaymentRoutingAlterModal :loadedFormDataOptions="this.tableItems" />
      <div class="mt-5">
        <b-button
          class="my-3"
          variant="outline-primary"
          v-b-modal:payment-routing-add-modal
        >
          Adicionar direcionamento
        </b-button>

        <b-button
          class="m-3"
          variant="primary"
          v-b-modal:payment-alter-modal
          v-if="tableItems && tableItems.length > 0"
        >
          Alterar ou excluir direcionamento
        </b-button>
      </div>
    </div>
    <div v-show="this.loading">
      <b-card>
        <b-skeleton type="AccordionList" width="100%"></b-skeleton>
        <b-skeleton-table
          :rows="tableFields.length"
          :columns="2"
          :hide-header="true"
        ></b-skeleton-table>
      </b-card>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import AccordionList from "../../../../components/accordion-list/AccordionList.vue";
import { getPaymentsRedirection } from "../../../../services/payment-options/paymentOptionsService";
import PaymentRoutingAddModal from "../forms/payment-routing/PaymentRoutingAddModal.vue";
import PaymentRoutingAlterModal from "../forms/payment-routing/PaymentRotingAlterModal.vue";
import PaymentRoutingTable from "../forms/payment-routing/PaymentRoutingTable.vue";
import { formatCpfCnpj } from "../../../../utils/format/formtUtils";
import PaymentPrincipal from './PaymentPrincipal.vue';

Vue.component("payment-routing-table", () =>
  import("../forms/payment-routing/PaymentRoutingTable.vue")
);

const tableFields = [{ key: "cnpj", label: "CNPJ", tdClass: "text-right" }];

export default {
  name: "payment-routing",
  components: {
    AccordionList,
    PaymentRoutingAddModal,
    PaymentRoutingAlterModal,
    PaymentPrincipal,
  },
  setup() {
    return { tableFields };
  },
  mounted() {
    const crm = this.$store.getters.getUserName;
    this.getRedirections(crm);
  },
  data() {
    return {
      tableItems: [],
      loading: true,
      principalPayment: {},
    };
  },
  methods: {
    async getRedirections(crm) {
      try {
        this.loading = true;
        const { group, payments} = await getPaymentsRedirection(crm);
        
        this.principalPayment = group;
        let tableItemsResp = payments;

        let tableItemsRespAux = [];
        tableItemsResp.map((payment) => {
          if (payment.cnpj != null && payment.cnpj != undefined) {
            tableItemsRespAux.push(payment);
          }
        });

        tableItemsRespAux.map((payment) => {
            payment.cnpj = formatCpfCnpj(payment.cnpj.padStart(14, "0"));
            payment.paymentDestination = payment.paymentDestination.map(
              (paymentDestination) => {
                paymentDestination.cnpj = formatCpfCnpj(
                  paymentDestination.cnpj.padStart(14, "0")
                );
                return paymentDestination;
              }
            );
            return payment;
        });
        this.tableItems = tableItemsRespAux;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.error("getContactsInfo", error);
      }
    },
    getCustomTable(item) {
      return (
        <PaymentRoutingTable paymentDestination={item.paymentDestination} />
      );
    },
  },
};
</script>

<style lang="scss">
.addresses-container td::before {
  text-align: left !important;
}

.addresses-container td:first-child {
  border-color: #eff2f5;
}

.custom-payment {
  margin-bottom: 1rem;
}
</style>
